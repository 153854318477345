<template>
	<div class="mainTem">

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">第三方WMS同步信息</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<!-- <li>
					<span>{{ $t('hytxs0000060') }}</span>
					<cusSelFuzzy ref="cusSelFuzzy" :size="'small'" @changeData="changCus"></cusSelFuzzy>
				</li> -->
				<li>
					<span>{{ $t('c944a6686d996ab3') }}</span>
					<whNoSelect ref="whNoSelect" :size="'small'" @changeData="changWhNo"></whNoSelect>
				</li>
				<li>
					<span>{{$t('i18nn_6cdece641436d7ab')}}</span>
				
					<el-select size="small" filterable clearable v-model="filterData.lgs_asyn_thrid_status"
						:placeholder="$t('2ad108ab2c560530')" style="width: 100px;" @change="initData()">
						<el-option v-for="item in selectOption.lgs_asyn_thrid_status" :key="item.code"
							:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li>
				<li>
					<span>{{$t('i18nn_76dc341edac65885')}}</span>
				
					<el-select
						size="small"
						filterable
						clearable
						v-model="filterData.bizType"
						:placeholder="$t('2ad108ab2c560530')"
						style="width: 100px;"
						@change="initData()"
					>
						<el-option v-for="item in selectOption.bizTypeList" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li>
				<li>
					<span>{{$t('i18nn_2594059036dfede5')}}</span>
					<el-date-picker
						v-model="filterData.commitDateArr"
						type="datetimerange"
						align="right"
						unlink-panels
						range-separator="-"
						:start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
						:end-placeholder="$t('i18nn_46f7be1133b3e2c6')"
						value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd HH:mm:ss"
						size="small"
						@change="initData()"
						:picker-options="pickerOptions"
					></el-date-picker>
				</li>
				<li>
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input type="text" v-model="filterData.keyword" size="small" clearable
						@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('i18nn_3ee4c9b76289e93a')"
						style="width: 180px;" />
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
						{{ $t('1e7246dd6ccc5539') }}
					</el-button>
				</li>
			</ul>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.frameConHeightWh1" style="width: 100%" size="small">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50"
					align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				
				<el-table-column prop="state" :label="$t('i18nn_6cdece641436d7ab')">
					<template slot-scope="scope">
						<el-tag v-if="'10'===scope.row.status" type="success">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag v-else-if="'20'===scope.row.status" type="warning">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag v-else type="info">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>
				
				<el-table-column prop="whNo" :label="$t('5acbec83efb27445')"></el-table-column>
				
				<el-table-column prop="cusName" :label="$t('hytxs0000060')"></el-table-column>
				
				<el-table-column prop="asNo" :label="$t('i18nn_9ac9d5c058d1465d')"></el-table-column>
				
				<el-table-column prop="bizType" :label="$t('i18nn_76dc341edac65885')"></el-table-column>
				<!-- <el-table-column prop="pushParams" :label="'pushParams'"></el-table-column> -->
				<el-table-column prop="thridOrderNo" :label="$t('i18nn_83b1b50eb00a9fb6')"></el-table-column>
				
				<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				
				
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="200">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_e1911e9360047dcb')}}</span>:{{scope.row.updateTime}}
								</li>
								<li>
									<span>{{$t('i18nn_e8564657bbe9ca53')}}</span>:{{scope.row.createTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>
				
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>
	
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	
	export default {
		components: {
			// cusSelFuzzy,
			whNoSelect
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false
		// 		},
		// 		type: Boolean
		// 	},
		// },
		data() {
			return {
				
				loading: false,
				
				loading_load: false,
				tableData: [],
				pickerOptions: this.$PickerDate.pickerOptions_1(),
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					lgs_asyn_thrid_status: [],
					bizTypeList:[{
						code:'SDS',
						codeText:this.$t('i18nn_684a2afb069b6016'),
						codeTextEn:this.$t('i18nn_684a2afb069b6016'),
					},{
						code:'SCC',
						codeText:this.$t('i18nn_de548b6f239487e4'),
						codeTextEn:this.$t('i18nn_de548b6f239487e4'),
					}, {
						code: 'STF',
						codeText: this.$t('i18nn_9dc7842043356291')+'/'+this.$t('i18nn_6373cb1d1204d580'),
						codeTextEn: this.$t('i18nn_9dc7842043356291')+'/'+this.$t('i18nn_6373cb1d1204d580'),
					}]
				},
				//查询，排序方式
				filterData: {
					orderBy: 'sort_no', //排序字段
					sortAsc: 'N', //desc降序，asc升序
					// userName: '',
					lgs_asyn_thrid_status: '',
					bizType:'',
					wh_location_type: '',
					wh_partner_type: '',
					commitDateArr: [],
					keyword: ''
				}
			};
		},
		activated() {
			
		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {
			//数据字典
			getDicData(['lgs_asyn_thrid_status'],
				(data) => {
					this.selectOption.lgs_asyn_thrid_status = data['lgs_asyn_thrid_status'];
				});
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.getPageData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
			},
			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				this.initData();
			},
		
			//查询数据
			serPageData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},

			//请求分页数据
			getPageData() {
				
				let commitDateArr = this.filterData.commitDateArr;
				let startCommitDate = '';
				let endCommitDate = '';
				if (commitDateArr && commitDateArr.length == 2) {
					startCommitDate = commitDateArr[0];
					endCommitDate = commitDateArr[1];
				}
				
				// let _this = this;
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhThirdWmsAsyncInfoList, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						userId: this.filterData.userId ? this.filterData.userId : null,
						whNo: this.filterData.whNo ? this.filterData.whNo : null,
						pushTimeStart :startCommitDate? startCommitDate : null,
						pushTimeEnd: endCommitDate? endCommitDate : null,
						status: this.filterData.lgs_asyn_thrid_status ? this.filterData.lgs_asyn_thrid_status : null,
						bizType: this.filterData.bizType ? this.filterData.bizType : null,
						
						keyword: this.filterData.keyword ? this.filterData.keyword : null
					})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// @import url(@/assets/css/client_module.less);
</style>
